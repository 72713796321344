import React, { useState, useEffect, useContext, useRef } from 'react'
import api from '../../components/api/AxiosConfig'
import './PageLogin.css'
import Auth from '../../components/modal/authmodal/Auth'
import { PageContext, SettingsContext, UserContext } from '../../context/AppContext';
import ModalInterface from '../../modules/modalInterface'
import CustomButton from '../../components/custom-button/CustomButton';
import utils from '../../modules/utils';
import Svg from '../../modules/svg';
import languages from '../../language';
import ModalInterval from '../../components/modal/interval-modal/ModalInterval';
import settings from '../../settings';
import RegisterOnLogin from '../../components/modal/register-on-login/RegisterOnLogin';



export default function PageLogin(props) {
    const { pageContext, setPageContext } = useContext(PageContext)
    const { settingsContext, setSettingsContext } = useContext(SettingsContext)
    const { userContext, setUserContext } = useContext(UserContext)
    const [buttonWidth, setButtonWidth] = useState()
    const inputRef = useRef(null)
    const [formData, setFormData] = useState({})
    const fullname = utils.generateRandomString(1)

    async function handleLogin(event) {
        console.log("missing", pageContext.missingToken)
        if (pageContext.missingToken) {
            console.log("missing:?")
            return;
        }
        if (!validateFields(formData.phoneNumber)) {
            ModalInterface.createPopup(pageContext, "שגיאה", "מספר הטלפון אינו תקין!", 'wrong-answer')
            return
        }
        try {
            const response = await api.post('/auth/login', {
                "login_info": formData.phoneNumber,
                "fullname": formData.fullname,
                "version": settingsContext.version
            })
            if (response.data.status === "failure") {

                if (response.data.message == "user doesnt exists") {
                    ModalInterface.createPopup(pageContext, "אימות", null, null, null, <RegisterOnLogin phoneNumber={formData.phoneNumber} />, false)
                    //     ModalInterface.createPopup(pageContext, "התראה" ,
                    //  languages.HE.errorMessages[response.data.message],
                    //   'wrong-answer')

                } else if (response.data.message == "must wait before sending another code") {
                    ModalInterface.createPopup(pageContext, null, null, null, null, <ModalInterval
                        title={"התראה"}
                        body={[languages.HE.errorMessages[response.data.message][0], languages.HE.errorMessages[response.data.message][1]]}
                        time={Math.round(response.data.time)}
                        img={'wrong-answer'} />)
                    // ModalInterface.createPopup(pageContext, "התראה" ,
                    // `${languages.HE.errorMessages[response.data.message][0]}
                    //  ${Math.round(response.data.time)}
                    //  ${languages.HE.errorMessages[response.data.message][1]}`,
                    //  'wrong-answer')
                } else if (response.data.message == "wrong phone") {
                    ModalInterface.createPopup(pageContext, 'התראה', languages.HE.errorMessages[response.data.message], 'wrong-answer')
                } else if (response.data.message == "game is unavailable") {
                    ModalInterface.createPopup(pageContext, 'התראה', languages.HE.errorMessages[response.data.message], 'wrong-answer')
                } else if (response.data.message == "users is not active") {
                    ModalInterface.createPopup(pageContext, 'התראה', languages.HE.errorMessages[response.data.message], 'wrong-answer')
                } else if (response.data.message == "update required") {
                    ModalInterface.createPopup(pageContext, 'התראה', languages.HE.errorMessages[response.data.message], 'wrong-answer')
                }

            } else {
                console.log("formData.phoneNumber", formData.phoneNumber)
                ModalInterface.createPopup(pageContext, "אימות", null, null, null, <Auth phonenumber={formData.phoneNumber} />)
            }
        }
        catch (error) {
            console.log(error);
        }
    }



    function validateFields(phonenumber) {
        let detectLoginInfo = null
        if (/^\d+$/.test(phonenumber)) {
            detectLoginInfo = "phone"
        }
        // if (/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(phonenumber)) {
        //     detectLoginInfo = "email"
        // }
        if (detectLoginInfo == "phone") {
            if (phonenumber.length != 10) {
                return false
            }
            if (phonenumber[0] != 0 && phonenumber[1] != 5) {
                return false
            }
        } else if (detectLoginInfo == null) {
            return false
        }

        return true
    }

    function handleChange(event) {
        if (event.target.name == "phoneNumber") {
            setFormData({
                ...formData,
                [event.target.name]: event.target.value
            })
        } else {
            setFormData({
                ...formData,
                fullname: event.target.value
            })
        }
    }

    useEffect(() => {
        setButtonWidth(inputRef.current.getBoundingClientRect().width)
        // window.location.hash = 'login'
    }, [])

    useEffect(() => {
        console.log("changed ", userContext.newUser)
        if (userContext.newUser) {
            console.log("UserContext.newUser ", userContext.newUser)
            handleLogin()
        }

        // window.location.hash = 'login'
    }, [userContext.newUser])



    return (
        <div className='login-container'>
            <div className='login-top'>
                <Svg fileName="big_logo" />
            </div>
            <div className='login-middle'>
                <div className='form-container'>
                    <h4>המשחק נגמר, תודה למשתתפים!</h4>
                    <form className='form'>
                        <input className='form-input' name={'phoneNumber'} placeholder='נא להזין אמצעי התחברות' maxLength={50} onChange={handleChange} ref={inputRef} required></input>
                        <input type={'text'} name={fullname} style={{ display: "none" }} onChange={handleChange}></input>
                        <CustomButton onClick={handleLogin} widthFold='200px' marginFold="5% 0px 0px 0px" width={buttonWidth}  backgroundGradiant={{ top: "rgba(203, 82, 66, 1)", bottom: "rgba(224, 52, 52, 1)" }} fontColor={"#fff"} content={"כניסה"} />
                    </form>
                </div>
                {/* <div className='join-group-container'>
                    <h5>אין לך קבוצה?</h5>
                    <a href='https://treasurehunt.co.il/mainpage/' target='_blank'><h5>קנה כניסה!</h5></a>
                </div> */}
            </div>
        </div>
    )
}
