import React , {useContext ,useEffect,useState} from 'react'
import './RegisterOnLogin.css'
import CustomButton from '../../custom-button/CustomButton'
import {PageContext , UserContext} from '../../../context/AppContext';
import ModalInterface from '../../../modules/modalInterface'
import validateFields from '../../../modules/validateFields';
import api from '../../api/AxiosConfig'
import ProgressInterface from '../../../modules/progressInterface';
import languages from '../../../language';
import VerifyUser from '../verify-user/VerifyUser';
import Auth from '../authmodal/Auth';
import PdfModal from '../pdf-modal/PdfModal';
import terms from '../../../terms.pdf'
import privacy from '../../../privacy.pdf'

function RegisterOnLogin({toggleForceUpdateת , phoneNumber}) {
    const {userContext , setUserContext } = useContext(UserContext)
    const {pageContext , setPageContext } = useContext(PageContext)
    const [formData , setFormData] = useState({})
    const [message , setMessage] = useState()
    const [userSeenTOS , setUserSeenTOS] = useState(false)
    const [usetSeenPOP , setUserSeenPOP] = useState(false)
    const [isTOSChecked, setIsTOSChecked] = useState(false);
    const [isATAChecked , setISATAChecked] = useState(false)
    const [deferredPrompt, setDeferredPrompt] = useState(null);
    const [dislayModal ,setDisplayModal] = useState(false)
    const [pdfPath , setPdfPath] = useState(null)

    function handlePopupVisibility(type){
        type == 'tos' ? setPdfPath('termofservice') : setPdfPath('privacypolicy')
        dislayModal ? setDisplayModal(false) : setDisplayModal(true)
    }

    function handleOutsideClick(){
        if(dislayModal){
            setDisplayModal(false)
        }
    }

    function handleTextChange(event){
        event.preventDefault()
        setFormData({
            ...formData,
            [event.target.name]: event.target.value
        })
    }

    const handleOnClick = async (isTOSChecked) => {
        let verify = validateFields.fieldValidate.validateNewUser(formData)
        if(!verify.status){
            setMessage(verify.message)
            return
        }
        console.log(isTOSChecked)
        if(!isTOSChecked){
            setMessage('יש לאשר את תנאי השימוש')
            return;
        }
        try {
            ProgressInterface.showProgressBar(setPageContext , pageContext)
            const response = await api.post('auth/register', {
                first_name: formData.first_name,
                last_name: formData.last_name,
                phone: phoneNumber,
                id_card: formData.id_card,
                agree_to_ads: isATAChecked
            })
            console.log(response.data)
            if(response.data == "success"){
                ModalInterface.closePopup(pageContext)
                console.log("timeout is on")
                setUserContext(userContext => ({
                    ...userContext,
                    newUser: true,
                }))
                // ModalInterface.createPopup(pageContext, "הוריי!", "נרשמתם בהצלחה! כעת עליכם לחזור לדף ההתחברות בכדי להתחיל במשחק")
            } else {
                setMessage(languages.HE.errorMessages[response.data.message])
            }
            // ProgressInterface.hideProgressBar(setPageContext , pageContext)
            
        } catch(err) {
            console.log(err);
        } finally{
            ProgressInterface.hideProgressBar(setPageContext , pageContext)
        }
    }
    function buttonsControl(){
        ModalInterface.addButtons(pageContext , [{"title": "שלח" , "disabled": true, onClick: () => handleOnClick(isTOSChecked)}])
    }

    // useEffect(() => {
    //     buttonsControl()
    // },[formData])

    function downloadURI(type) 
    {
        const checktype = type == 'TOU'
        var link = document.createElement("a");
        link.setAttribute('download', checktype ? 'terms.pdf' : 'privacypolicy.pdf');
        link.href = checktype ? terms : privacy;
        document.body.appendChild(link);
        link.click();
        link.remove();
    }

    const handleCheckBox = (e)=>{
        console.log("checkbox", e.target.checked)
        setIsTOSChecked(e.target.checked)
    }

  return (
    <>
        <PdfModal pdfName={pdfPath} visibility={dislayModal} handleVisibility={handleOutsideClick}/>
        <div className='add-user-container'>
            <div className='add-user-header'>
            <p>הרשמה</p>
            <div style={{color: "white" , display: "block", fontSize:'12px', minHeight:'16px'}}>{message}</div>
            <div className='add-user-exit-button' onClick={() => ModalInterface.closePopup(pageContext)}> 
                X
            </div>
            </div>
            
            <form>
                <div className='add-user-top'>
                    <input type='text' autoComplete='off' name='first_name' placeholder='שם פרטי' onChange={handleTextChange} required></input>
                    <input type='text' autoComplete='off' name='last_name' placeholder='שם משפחה' onChange={handleTextChange} required></input>
                </div>
                {/* <div className='add-user-middle'>
                    <input placeholder='מספר טלפון' name='phone' minLength={10} maxLength={10} onChange={handleTextChange}></input>
                    <input placeholder='דואר אלקטרוני' name='email' onChange={handleTextChange}></input>
                </div> */}
                <input style={{display: "none"}} name='id_card'/>
                <div className='add-user-middle'>
                    <div className='form-group-terms'>
                        <input className='checkbox' checked={isTOSChecked} type={"checkbox"}  onChange={handleCheckBox} required></input>
                        <label>קראתי ואני מסכים ל<a onClick={() => downloadURI('TOU')} >תנאי השימוש</a > <a onClick={() => downloadURI('')}>ומדיניות הפרטיות</a></label>
                    </div>
                </div>
                <CustomButton
                    onClick={() => handleOnClick(isTOSChecked)}
                    content={"שלח"}
                    // width={"calc(40% - 10px)"}
                    backgroundGradiant={{ top: "rgba(203, 82, 66, 1)", bottom: "rgba(224, 52, 52, 1)" }}
                />
            </form>
                <div className='add-user-bottom'>
                    {/* <CustomButton onClick={handleOnClick} content={"שלח"} width={"calc(40% - 10px)"} backgroundGradiant={{top: "rgba(21, 138, 110, 1)" , bottom: "rgba(3, 62, 81, 1)"}}></CustomButton> */}
                </div>
        </div>
    </>

  )
}

export default RegisterOnLogin