import React, { useState , useContext , useEffect} from 'react'
import api from '../../components/api/AxiosConfig'
import './PageWelcome.css'
import {UserContext , PageContext} from '../../context/AppContext';
import CustomButton from '../../components/custom-button/CustomButton';
import utils from '../../modules/utils';
import ModalInterface from '../../modules/modalInterface';
import AddToHomeScreen from '../../components/add-to-home-screen/AddToHomeScreen';
import languages from '../../language';
import PdfModal from '../../components/modal/pdf-modal/PdfModal';
import Svg from '../../modules/svg';



export default function PageWelcome(props) {
    const {userContext } = useContext(UserContext)
    const {pageContext , setPageContext } = useContext(PageContext)
    const [uuid , setUuid] = useState('')
    const [isTOSChecked, setIsTOSChecked] = useState(false);
    const [isATAChecked , setISATAChecked] = useState(false)
    const uuidString = utils.generateRandomString(2)
    const [deferredPrompt, setDeferredPrompt] = useState(null);
    const [dislayModal ,setDisplayModal] = useState(false)
    const [pdfPath , setPdfPath] = useState(null)
    const [userSeenTOS , setUserSeenTOS] = useState(false)
    const [usetSeenPOP , setUserSeenPOP] = useState(false)



    async function updateFirstLogin(){
        try {
            // const response = await api.post('/auth/first-login' , {
            //     "user_id": userContext.userID,
            //     "group_id": userContext.groupID,
            //     "uuid": uuid,
            //     "agree_to_ads": isATAChecked
            // }, {withCredentials: true}
            // )
            // if(response.data['status'] === "success"){
            setPageContext(prev=> ({
                ...prev,
                showWelcome: false,
                showLogin: true
            }))
            // }
        } 
        catch (error) {
            console.log(error);
        }
    }

    function handleChange(event){
        setUuid(event.target.value)
    }

    function handleSubmit(event){
        //Open terms popup
        // if(isTOSChecked){
        updateFirstLogin()
        // } else {
        //     ModalInterface.createPopup(pageContext , "התראה" , "עלייך להסכים לתנאי השירות על מנת להמשיך!" , "wrong-answer")
        // }
    }

    function handlePopupVisibility(type){
        type == 'tos' ? setPdfPath('termofservice') : setPdfPath('privacypolicy')
        dislayModal ? setDisplayModal(false) : setDisplayModal(true)
    }

    function handleOutsideClick(){
        if(dislayModal){
            setDisplayModal(false)
        }
    }


    const handleInstall = () => {
        if (deferredPrompt) {
          deferredPrompt.prompt();
          deferredPrompt.userChoice.then((choiceResult) => {
            if (choiceResult.outcome === 'accepted') {
              console.log('User installed the app');
            } else {
              console.log('User dismissed the app installation');
            }
            setDeferredPrompt(null);
          });
        }
        ModalInterface.closePopup(pageContext)
      };

    useEffect(() => {
        const handleBeforeInstallPrompt = (event) => {
          event.preventDefault();
          setDeferredPrompt(event);
        };
    
        window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
    
        return () => {
          window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
        };
      }, []);

    //   useEffect(() => {
    //     window.location.hash = 'welcome'
    //   },[])

  return (
    
        
        <div className='welcome-container'>
            <PdfModal pdfName={pdfPath} visibility={dislayModal} handleVisibility={handleOutsideClick}/>
            <div className='welcome-logo'>
                <Svg style={{width:'90%'}} fileName='logo_junior_big' />
            </div>
            <div className='page-top'>
                <h3 style={{fontWeight:'600'}}>ברוכים הבאים</h3>
                <p className='paragraph-welcome'>{languages.HE.gameTexts['welcome page paragraph']}</p>
            </div>
            <div className='page-middle'>
            <iframe width="560" height="315" src="https://www.youtube.com/embed/lC4XOY-n1qs?si=YUb6oo9rb7n6S5Bn" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share; fullscreen;" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
            </div>
            <div className='page-bottom'>
                <CustomButton onClick={handleSubmit} width={"263px"} backgroundGradiant={{ top: "rgba(203, 82, 66, 1)", bottom: "rgba(224, 52, 52, 1)" }} fontColor={"#fff"} content={"התחל משחק"}/>
            </div>
            
        </div>
        
  
  )
}
