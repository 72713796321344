import React, { useEffect , useContext , useState } from 'react'
import './CardSpread.css'
import {UserContext , PageContext} from '../../context/AppContext';
import Card from '../card/Card';
import BGA from '../../images/minigame-bg/bg-brown.svg'
import MinigameCard from '../card/minigamecard/MinigameCard';
import ProgressInterface from '../../modules/progressInterface';
import api from '../api/AxiosConfig'
import ModalInterface from '../../modules/modalInterface';
import languages from '../../language';

function CardSpread() {
    const {userContext } = useContext(UserContext)
    const {pageContext , setPageContext } = useContext(PageContext)
    const [deckTitle , setDeckTitle] = useState()
    const [deckColor , setDeckColor] = useState()
    // const [questionList , setQuestionList] = useState({})
    const [cardWidth , setCardWidth] = useState("206px")
    const [cardHeigth , setCardHeigth] = useState("360px")
    
    function handleSize(){
        if(window.innerWidth <= 768){
          setCardWidth("149px")
          setCardHeigth("260px")
        }
    }

    useEffect(() => {
        handleSize()
        getQuestionInfo()
    }, [pageContext.selectedQuestion, pageContext.selectedDeck])

    let questionList
    async function getQuestionInfo(){
        try {
          ProgressInterface.showProgressBar(setPageContext , pageContext)
          const response = await api.post('/game/get-question-info' , {
            "user_id": userContext.userID,
            "minigame_id": JSON.stringify(pageContext.currentMinigame),
            "group_id": userContext.groupID,
            "current_deck": pageContext.selectedDeck
          })
          const res = await response
          console.log(res);
          if(res.data){
            if(response.data){
              let tempObj = response.data.info
              questionList = tempObj
              setDeckTitle(response.data.title)
              if(pageContext.currentMinigame != 3){
                setDeckColor(response.data.color)
              } else {
                let colors
                if(window.innerWidth < 480){
                  colors = ['white']
                } else {
                  colors = ['white']
                }
                setDeckColor(colors)
              }
              
            }
          }
        } catch (error) {
          console.log(error);
        }
        ProgressInterface.hideProgressBar(setPageContext , pageContext)
        console.log("setting new question list");
        setPageContext(prev=> ({
          ...prev,
          questionList: questionList
        }))
        
    }

    async function handleQuestionSelect(index){
        const response = await api.post('/game/request-question-access', {
          user_id: userContext.userID,
          group_id: userContext.groupID,
          question_id: index,
          minigame_id: pageContext.currentMinigame
        })
        if(response.data.status == "success"){
          setPageContext(prev=> ({
            ...prev,
            showMinigame: false,
            showQuestions: true,
            showSpread: false,
            selectedQuestion: index,
            
          }))
        } else {
          getQuestionInfo()
          ModalInterface.createPopup(pageContext , "שגיאה" , languages['HE'].errorMessages[response.data.message] , 'wrong-answer')
        }
        
    }

  return (
    <div>
        <div className="spread-container" style={{display: pageContext.showSpread ? 'flex' : 'none'}}>
          { pageContext.questionList ? Object.keys(pageContext.questionList).map((q , index) => {
            return(
                <Card key={index} width={cardWidth} height={cardHeigth} radius={"4px"} onClickEvent={() => handleQuestionSelect(pageContext.questionList[q].id)} done={pageContext.questionList[q].question_status} position={'static'} boxShadow={'none'} backgroundImage={Array.isArray(deckColor) ? deckColor[index] : deckColor} animation={'animate__flipInY'}>
                    <MinigameCard done={pageContext.questionList[q].question_status} riddle={"חידה " + (pageContext.questionList[q].question_num)} location={deckTitle}></MinigameCard>
                </Card>
            )
            })
          : null}       
        </div>
    </div>
  )
}

export default CardSpread