import React, { useEffect, useRef, useState, useContext } from 'react';
import Svg from '../../../modules/svg'
import CustomButton from '../../custom-button/CustomButton'
import './CameraInfoCard.css'
import api from '../../api/AxiosConfig'
import { UserContext, PageContext } from '../../../context/AppContext';
import ModalInterface from '../../../modules/modalInterface';
import languages from '../../../language';

export default function CameraInfoCard(props) {
  const { userContext } = useContext(UserContext)
  const { pageContext, setPageContext } = useContext(PageContext)
  const [isScrolling, setIsScrolling] = useState(true);
  const [photoDetails, setPhotoDetails] = useState("")
  const [buttonAdjustments, setButtonAdjustments] = useState({ long: {} })
  const [scale, setScale] = useState(1)
  const containerRef = useRef(null)
  const [scrollPos, setScrollPos] = useState(8)
  let scrollInterval;
  const duration = 5000;


  function handleSize() {
    if (window.innerWidth <= 768) {
      setButtonAdjustments({
        ...buttonAdjustments,
        long: {
          width: "219px",
          height: "43px"
        }
      })
    } else {
      setButtonAdjustments({
        ...buttonAdjustments,
        long: {
          width: "300px",
          height: "59px"
        }
      })
    }
  }

  async function getPhotoDetails() {
    try {
      const response = await api.post('/game/get-question-photo-details', {
        user_id: userContext.userID,
        group_id: userContext.groupID,
        minigame_id: pageContext.currentMinigame,
        question_id: pageContext.selectedQuestion
      })
      if (response.data.status == "success") {
        setPhotoDetails(response.data.info[0].photo_info)
      } else {
        ModalInterface.createPopup(pageContext, "שגיאה", languages.HE.errorMessages[response.data.message], 'wrong-answer')
      }
    } catch (err) {
      console.log(err);
    }
  }

  function resizePirate() {
    scale == "1" ? setScale(2) : setScale(1)
  }

  useEffect(() => {
    getPhotoDetails()
    handleSize()
  }, [])


  useEffect(() => {
    const scrollingDiv = containerRef.current;
    const scrollHeight = scrollingDiv.scrollHeight;
    const startTime = performance.now();
    let requestId;

    function scroll() {
      const elapsedTime = performance.now() - startTime;
      const progress = elapsedTime / duration;
      const scrollTo = progress * (scrollHeight - scrollingDiv.clientHeight);
      scrollingDiv.scrollTop = scrollTo;

      if (elapsedTime < duration && isScrolling) {
        requestId = requestAnimationFrame(scroll);
      }
    }

    if (isScrolling) {
      requestId = requestAnimationFrame(scroll);
    }

    return () => {
      cancelAnimationFrame(requestId);
    };
  }, [isScrolling]);

  const handleDivClick = () => {
    setIsScrolling(false);
  };


  return (
    <div className='camera-info-container'>
      <div className='camera-info-card-content'>
        <div className='camera-info-card-content-top'>
          <Svg fileName='camera_small_icon' />
        </div>
        <div ref={containerRef} className='camera-info-card-content-mid animate__animated' onClick={handleDivClick}>
          <h3>{languages.HE.gameTexts.photo_details[6]}</h3>
          <h4>{photoDetails}</h4>
          <br></br>
          <p>{languages.HE.gameTexts.photo_details[0]}</p>
          <p>{languages.HE.gameTexts.photo_details[1]}</p>
          <p>{languages.HE.gameTexts.photo_details[2]}</p>
          <p>{languages.HE.gameTexts.photo_details[3]}</p>
          <p>{languages.HE.gameTexts.photo_details[4]}</p>
          <p>{languages.HE.gameTexts.photo_details[5]}</p>

        </div>
        <div className='camera-info-photo-container' style={{ transform: `scale(${scale})` }}>
          <Svg onClick={() => resizePirate()} fileName={"photo-info"} />
        </div>

        <div className='camera-info-card-content-bottom'>
          <CustomButton onClick={() => props.handleCameraInfo()} width={buttonAdjustments.long.width} height={buttonAdjustments.long.height} backgroundGradiant={{ top: "rgba(203, 82, 66, 1)", bottom: "rgba(224, 52, 52, 1)" }} content={"המשך"}></CustomButton>
        </div>
      </div>
    </div>
  )
}
