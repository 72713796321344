import React, { useEffect, useRef, useState , useContext} from 'react';
import Svg from '../../../modules/svg'
import CustomButton from '../../custom-button/CustomButton'
import './CameraCard.css'
import ModalInterface from '../../../modules/modalInterface';
import { PageContext } from '../../../context/AppContext';

export default function CameraCard(props) {
  const {pageContext , setPageContext } = useContext(PageContext)
   const [selectedFile , setSelectedFile] = useState(null);
   const [buttonAdjustments, setButtonAdjustments] = useState({long: {}})
   const allowedTypes = ['image/png', 'image/jpeg', 'image/jpg'];
   const [imageSrc, setImageSrc] = useState(null);
   const fileInputRef = useRef();

   function handleFileInput(event){
    if(!event.target.files[0]){
      return
    }
    const file = event.target.files[0]
    if (file && allowedTypes.includes(file.type)) {
      setSelectedFile(file);
    } else {
      setSelectedFile(null);
      ModalInterface.createPopup(pageContext,'שגיאה', 'ניתן להעלות רק קבצים מסוג PNG / JPEG / JPG' , 'wrong-answer')
      return
    }

    const reader = new FileReader()
    reader.onload = () => {
      setImageSrc(reader.result)
    }
    reader.readAsDataURL(file)
    event.target.value = ''
   }

   function handleCameraClick(){
    fileInputRef.current.click();
   }

   function handleSize(){
    if(window.innerWidth <= 768){
      setButtonAdjustments({
        ...buttonAdjustments,
        long: {
          width: "219px",
          height: "43px"
        }
      })
    } else {
      setButtonAdjustments({
        ...buttonAdjustments,
        long: {
          width: "300px",
          height: "59px"
        }
      })
    }
  }

  function resetStates(){
    setImageSrc(null)
    setSelectedFile(null)
  }

  useEffect(() => {
    handleSize()
  },[])

  return (
    <div className='camera-card-content'>
        <h3>צלם את האתר אליה מתכוונת החידה</h3>
        <div className='camera-container'>
          <div className='camera-sub-container' onClick={handleCameraClick}>
            <form style={{display: 'none'}}>
              <input
              type='file'
              onChange={handleFileInput}
              ref={fileInputRef}
              style={{display: 'none'}}
               disabled={imageSrc}
               accept='.png,.jpeg,.jpg'
              />
            </form>
            {imageSrc ? 
            <img onClick={handleCameraClick} className='camera-card-uploaded' src={imageSrc} alt='selected file' /> :
            <div>
              <Svg  fileName='camera_place_holder' />
              <h6>לחץ כדי לפתוח מצלמה</h6>
            </div>
            
            }    
          </div>
          <CustomButton onClick={() =>props.handleSubmit(selectedFile)} width={buttonAdjustments.long.width} height={buttonAdjustments.long.height} backgroundGradiant={{top: "rgba(21, 138, 110, 1)" , bottom: "rgba(3, 62, 81, 1)"}} content={"המשך"} oneTime={false} block={props.disalbeUpload}></CustomButton>
          {!imageSrc ? 
          <CustomButton onClick={() => props.handleCameraInfo()}  width={buttonAdjustments.long.width} height={buttonAdjustments.long.height} boxShadow={"none"} fontColor={"rgba(55, 47, 43, 1)"} backgroundColor={"rgba(0,0,0,0)"} content={"חזור"}></CustomButton>
          :
          <CustomButton onClick={() => resetStates()} width={buttonAdjustments.long.width} height={buttonAdjustments.long.height} boxShadow={"none"} fontColor={"rgba(55, 47, 43, 1)"} backgroundColor={"rgba(0,0,0,0)"} content={"צלם שוב"}></CustomButton>}
        </div>
        
    </div>
  )
}
