import React, { useContext, useEffect, useRef, useState } from 'react'
import './Header.css'
import Cookies from 'js-cookie';
import Svg from '../../modules/svg'
import { UserContext, PageContext , SettingsContext } from '../../context/AppContext';
import BackButton from '../back-button/BackButton';
import SideMenu from '../sidemenu/SideMenu';
import ModalInterface from '../../modules/modalInterface';
import utils from '../../modules/utils';
import api from '../api/AxiosConfig'
import languages from '../../language';
import settings from '../../settings';
// import rules from '../../new-rules.pdf'

export default function Header() {
  const { userContext, setUserContext } = useContext(UserContext)
  const { pageContext, setPageContext } = useContext(PageContext)
  const { settingsContext, setSettingsContext } = useContext(SettingsContext)
  const [sideMenuVisible, setSideMenuVisible] = useState(false)
  const [showDropdown, setShowDropDown] = useState(false)
  const [questionNum, setQuestionNum] = useState()
  const logoutRef = useRef(null)

  async function moveToCoinBank() {
    if (pageContext.showCamera || pageContext.showWelcome || pageContext.showLogin) {
      return
    }
    try {
      setSideMenuVisible(false)
      if (userContext.groupID) {
        setPageContext(prev=> ({
          ...prev,
          showLogin: false,
          showWelcome: false,
          showStartGame: false,
          showMinigame: false,
          showQuestions: false,
          showUserInfo: false,
          showCoinBank: true,
          showSpread: false,
          showPrizes: false,
          showProgress: false,
          selectedDeck: 0,
        }))
      }
       
    } catch (err) {
      console.log(err);
    }


  }

  async function moveToPrizes() {
    if (pageContext.showCamera || pageContext.showWelcome || pageContext.showLogin) {
      return
    }
    try {
      setSideMenuVisible(false)
      if (userContext.groupID) {
        setPageContext(prev=> ({
          ...prev,
          showLogin: false,
          showWelcome: false,
          showStartGame: false,
          showMinigame: false,
          showQuestions: false,
          showUserInfo: false,
          showCoinBank: false,
          showPrizes: true,
          showSpread: false,
          showProgress: false,
          selectedDeck: 0,
        }))
      }
       
    } catch (err) {
      console.log(err);
    }


  }

  function moveToGameStart() {
    if (pageContext.showLogin || pageContext.showWelcome || PageContext.StartGame || pageContext.showCamera) {
      return
    }
    setSideMenuVisible(false)
    if (userContext.groupID) {
      setPageContext(prev=> ({
        ...prev,
        showLogin: false,
        showWelcome: false,
        showStartGame: true,
        showMinigame: false,
        showQuestions: false,
        showUserInfo: false,
        showSpread: false,
        showProgress: false,
        showCoinBank: false,
        showPrizes:false,
        selectedDeck: 0,
        selectedQuestion: 0
      }))
    }
  }

  function moveToUserInfo() {
    if (pageContext.showLogin || pageContext.showWelcome || PageContext.StartGame || pageContext.showCamera) {
      return
    }
    setSideMenuVisible(false)
    if (userContext.groupID) {
      setPageContext(prev=> ({
        ...prev,
        showLogin: false,
        showWelcome: false,
        showStartGame: false,
        showMinigame: false,
        showQuestions: false,
        showUserInfo: true,
        showSpread: false,
        showProgress: false,
        showCoinBank: false,
        showPrizes:false,
        selectedDeck: 0,
        selectedQuestion: 0
      }))
    }
  }

  async function getQuestionNum() {
    if (pageContext.showQuestions && pageContext.selectedQuestion) {
      try {
        const response = await api.post('/game/get-selected-question-number', {
          question_id: pageContext.selectedQuestion
        })
        if (response.data.status == "success") {
          setQuestionNum(response.data.questionNum)
        } else {
          setQuestionNum(0)
        }
      } catch (err) {
        console.log(err);
      }
    }

  }

function getHeader() {
//     if (pageContext.showLogin || pageContext.showWelcome || pageContext.showStartGame) {
//       return null
//     }
//     if (pageContext.showMinigame) {
//       if (pageContext.minigameStatus == 1) {
//         return <Svg fileName='mobile_header_logo' />
//       } else {
//         return <Svg fileName='logo_junior'></Svg>
//     }
// }
// if (pageContext.showQuestions && pageContext.selectedQuestion) {
//     return " חידה " + questionNum
// }
// if (pageContext.showCoinBank) {
//     return (
//         <div className='header-center-phone-text'>
//           <h3>מטבעות</h3>
//           <h5>{userContext.coinBalance}</h5>
//         </div>
//       )
//     }
    return<Svg fileName='big_kids'></Svg>
}

  function toggleDropDown() {
    setShowDropDown(!showDropdown)
  }

  function toggleSideMenu() {
    setSideMenuVisible(prev => !prev)
  }

  function handleLogout() {
    if (!userContext.userID) {
      ModalInterface.createPopup(pageContext, "התראה", 'אינך יכול להתנתק אם אינך מחובר', 'wrong-answer')
      toggleDropDown()
      if (sideMenuVisible) {
        toggleSideMenu()
      }
      return
    } else {
      if (sideMenuVisible) {
        toggleSideMenu()
      }
      ModalInterface.createPopup(pageContext, "התראה", "האם אתה בטוח שאתה רוצה להתנתק?", null,
        [{ title: "כן", onClick: () => utils.handleLogout(setUserContext, setPageContext, pageContext.setModalState, pageContext.recaptchaState, pageContext.setRecaptchaState, pageContext.modalState, pageContext, pageContext.showProgress , pageContext.setShowProgress, pageContext.soundContext , pageContext.setSoundContext) },
        { title: "לא", onClick: () => ModalInterface.closePopup(pageContext) }], null, false)
    }

    toggleDropDown()
  }

  function setScreenModal(type) {
    setSideMenuVisible(false)
    let domain, location , extra
    if (window.location.hostname == 'dev.scavenger.dml.co.il') {
      domain = "scavenger-hunt-wp.dml.co.il"
    } else {
      domain = "treasurehunt.co.il"
    }
    if (type == "rules") {
      location = "rules"
    } else if (type == "rewards") {
      location = "rewards"
    } else if (type == "contact-us") {
      location = "contact"
      extra = "#contact-form-anchor"
    } else if (type == "did-you-know") {
      location = "הידעת"
    } else {
      return
    }

    setPageContext(prev => ({
      ...prev,
      screenModal: true,
      screenUrl: `https://${domain}/${location}/?app=true/${extra}`
    }))
  }

  useEffect(() => {

  }, [userContext.coinBalance])

  useEffect(() => {
    getQuestionNum()
  },[pageContext.selectedQuestion])

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (logoutRef.current && !logoutRef.current.contains(event.target) && showDropdown) {
        console.log("outside");
        if(event.target.alt != "drop-down"){
          toggleDropDown()
        }
       
      }
    };

    document.addEventListener('mousedown', handleOutsideClick);

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [showDropdown]);



  return (
    <div className='header-container'>
      <div className='header-web'>
        <div className='header-right'>
          <Svg fileName="logo_header" onClick={() => moveToGameStart()} />
        </div>
        <div className='header-center'>
          <a onClick={() =>setScreenModal("rules")} target='_blank'>חוקי המשחק</a>
          <a onClick={() =>setScreenModal("rewards")} target='_blank'>פרסים</a>
          <a onClick={() =>setScreenModal("contact-us")} target='_blank'>צור קשר</a>
          <a onClick={() =>setScreenModal("did-you-know")} target='_blank'>הידעת?</a>
          
        </div>
        <div className='header-left'>
        <div className='version-control'>version {settingsContext.version}</div>
          <div onClick={() => moveToCoinBank()} className='coins'>
            <Svg fileName="coin-holder" />
            <span className='coin-balance'>{userContext.coinBalance}</span>

          </div>
          <div className='user-icon'>
            <Svg fileName='user-icon' onClick={() => moveToUserInfo()} />
          </div>
          <div className={`drop-down`}>
            <Svg fileName={"droparrow"} alt={"drop-down"} onClick={() => toggleDropDown()} />
          </div>
          {showDropdown ?
            <div className='drop-down-logout' ref={logoutRef}>
              <p onClick={() => handleLogout()}>התנתק</p>
            </div> : null}
        </div>
      </div>
      <div className='header-phone'>
        <div className='header-right-phone'>

          {(pageContext.showMinigame || (pageContext.showQuestions && !pageContext.showCamera) || pageContext.showUserInfo || pageContext.showCoinBank || pageContext.showScanner || pageContext.showPrizes) ?
            <div>
              <BackButton isMobile={true} />
            </div>

            : null
          }
        </div>
        <div className='header-center-phone'>
          {getHeader()}
        </div>
        <div className='header-left-phone'>
        <div onClick={() => moveToCoinBank()} className='coins-mobile'>
            <Svg fileName="coin-holder" />
            <span className='coin-balance'>{userContext.coinBalance}</span>

          </div>
          <div className='hamburger-menu'>
            <Svg onClick={() => toggleSideMenu()} fileName="hamburger_menu" />
          </div>
        </div>
        {<SideMenu setScreenModal={setScreenModal} show={sideMenuVisible} handleLogout={handleLogout} toggle={toggleSideMenu} userCoins={userContext.coinBalance} moveToCoinBank={moveToCoinBank} moveToUserInfo={moveToUserInfo} moveToPrizes={moveToPrizes} />}
      </div>
    </div>
  )
}

