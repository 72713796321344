import React , {useContext, useEffect, useState} from 'react'
import './BackButton.css'
import { PageContext , UserContext} from '../../context/AppContext';
import api from '../api/AxiosConfig'
import Svg from '../../modules/svg';

export default function BackButton({isMobile}) {
    const {pageContext , setPageContext } = useContext(PageContext)
    const {userContext , setUserContext } = useContext(UserContext)
    const [disabled , setDisabled] = useState(false)
    const [questionNum, setQuestionNum] = useState()
    const [title , setTitle] = useState("")

    let styleSheet = {}

    async function getQuestionNum() {
        if (pageContext.showQuestions && pageContext.selectedQuestion) {
          try {
            const response = await api.post('/game/get-selected-question-number', {
              question_id: pageContext.selectedQuestion
            })
            if (response.data.status == "success") {
              setQuestionNum(response.data.questionNum)
              
            } else {
              setQuestionNum(0)
            }
            getRiddle(response.data.questionNum ? response.data.questionNum : 0)
          } catch (err) {
            console.log(err);
          }
        }
        
      }

    useEffect(() => {
        getQuestionNum()
        getRiddle()
    }, [pageContext.selectedQuestion])

    if(window.innerWidth <= 768){
        styleSheet = {
            position: 'relative',
            top: 0,
            right: 0
        }
    }

    function getRiddle(num){
        if(pageContext.showQuestions && window.innerWidth > 480){
            setTitle(`חידה ${num}`)
        } else {
            setTitle("")
        }
        
    }

    function handleBackButton(){
        if(disabled){
            return
        }
        setDisabled(true)
        setTimeout(() => setDisabled(false) , 500)
        if(pageContext.modalState.visible){
            return
        }
        if(pageContext.showSpread && pageContext.currentMinigame == 3){
            setPageContext(prev=> ({
                ...prev,
                showStartGame: true,
                showMinigame: false,
                selectedDeck: 0,
            }))
        } else if(pageContext.showSpread){
            setPageContext(prev=> ({
                ...prev,
                showSpread: false,
                selectedDeck: 0,
            }))
        }
        else if(pageContext.showMinigame){
            setPageContext(prev=> ({
                ...prev,
                showStartGame: true,
                showMinigame: false,
                selectedDeck: 0,
            }))
        }
        else if(pageContext.showQuestions){
            if(pageContext.currentMinigame == 4){
                setPageContext(prev=> ({
                    ...prev,
                    showQuestions: false,
                    showMinigame: true,
                    showSpread: false,
                    selectedQuestion: 0,
                }))
            } else {
                setPageContext(prev=> ({
                    ...prev,
                    showQuestions: false,
                    showMinigame: true,
                    showSpread: true,
                    selectedQuestion: 0,
                }))
            }
            
        }
        else if(pageContext.showUserInfo){
            setPageContext(prev=> ({
                ...prev,
                showUserInfo: false,
                showStartGame: true,
            }))
        }
        else if(pageContext.showCoinBank){
            setPageContext(prev=> ({
                ...prev,
                showCoinBank: false,
                showStartGame: true,
            }))
        }
        else if(pageContext.showScanner){
            setPageContext(prev=> ({
                ...prev,
                showScanner: false,
                showCoinBank: true
            }))
        }
        else if(pageContext.showPrizes){
            setPageContext(prev=> ({
                ...prev,
                showPrizes: false,
                showStartGame: true
            }))
        }
    }

    useEffect(() => {

    },[pageContext])

  return (
    <div className='back-button-container' style={styleSheet}>
        <div className='back-button-overlay' onClick={handleBackButton}>
            <Svg fileName='back-arrow'  />
            {window.innerWidth < 480 ? <div style={{fontSize: '16px'}}>אחורה</div> : null }
        </div>
        
        {title}
        {pageContext.showCoinBank && window.innerWidth > 480 ? 
        <div className='back-button-coins-container'>
        <div className='back-button-coins'>
            <Svg fileName="coin-holder"/>
            <span className='back-button-coin-balance'>{userContext.coinBalance}</span>
        </div>
        </div> : null}
    </div>
  )
}
