import React, { useContext, useState, useEffect } from 'react'
import './PageUserInfo.css'
import { PageContext, UserContext , SettingsContext} from '../../context/AppContext';
import ProgressInterface from '../../modules/progressInterface'
import UserBox from '../../components/user-box/UserBox'
import validateFields from '../../modules/validateFields'
import Svg from '../../modules/svg'
import api from '../../components/api/AxiosConfig'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit , faFloppyDisk } from '@fortawesome/free-solid-svg-icons'
import CustomButton from '../../components/custom-button/CustomButton';
import ModalInterface from '../../modules/modalInterface';
import AddUser from '../../components/modal/add-user/AddUser';
import languages from '../../language';


function PageUserInfo({show}) {
    const {userContext , setUserContext } = useContext(UserContext)
    const {pageContext , setPageContext } = useContext(PageContext)
    const { settingsContext, setSettingsContext } = useContext(SettingsContext)
    const [isLeader , setIsLeader] = useState(false)
    const [memberList,setMemberList] = useState({})
    const [groupName , setGroupName] = useState()
    const [editGroupName , setEditGroupName] = useState()
    const [isEdit , setIsEdit] = useState(false)
    const [displaySave , setDisplaySave] = useState(false)
    const [remainingUnregistered , setremainingUnregistered] = useState(0)
    const [forceUpdate , setForceUpdate] = useState(0)
    const [spinnerArray , setSpinnerArray] = useState([])
    const [soloPlayer , setSoloPlayer] = useState(false)
    const colors = ["#9D8F5E" , " #5E9D7F" , "#915E9D" , "#9D5E5E" , "#5E789D" , "#808080" , "#FF9933"]



    async function getGroup() {
        try {
            ProgressInterface.showProgressBar(setPageContext, pageContext)
            const response = await api.post('game/get-group-members', {
                group_id: userContext.groupID
            })
            if (response.data.status === "success") {
                setMemberList([...response.data.members])
                setGroupName(response.data.name)
                setSoloPlayer(response.data.solo)
                initSpinnerArray(response.data.members.length)
                setremainingUnregistered(response.data.unregisteredLeft)
            }
            ProgressInterface.hideProgressBar(setPageContext, pageContext)
        } catch (err) {
            console.log(err);
        }
        ProgressInterface.hideProgressBar(setPageContext, pageContext)
    }

    function initSpinnerArray(len) {
        for (let i = 0; i < len; ++i) {
            setSpinnerArray((prev) => ({
                ...prev,
                [i]: false
            }))
        }
    }

    function getColor() {
        let len = colors.length
        let random = Math.floor(Math.random() * len)
        let color = colors.pop(random)

        return color;
    }

    function toggleNameChange() {
        if (!isLeader) {
            ModalInterface.createPopup(pageContext, 'שגיאה', 'רק הקפטן של הקבוצה יכול לשנות שם!', 'wrong-answer')
            return
        }
        if (isEdit) {
            setDisplaySave(false)
            setIsEdit(false)
        } else {
            setDisplaySave(true)
            setIsEdit(true)
        }
    }

    function toggleForceUpdate() {
        if (forceUpdate == 1) {
            setForceUpdate(0)
        } else {
            setForceUpdate(1)
        }
    }

    function handleText(event) {
        setEditGroupName(event.target.value)
    }

    async function getGroupLeader() {
        try {
            const response = await api.post('/game/get-team-leader', {
                user_id: userContext.userID,
                group_id: userContext.groupID
            })
            if (response.data.status == "success") {
                if (userContext.userID == response.data.team_leader.team_leader) {
                    setIsLeader(true)
                }
            }

        } catch (err) {
            console.log(err);
        }
    }

    async function verifyGroupName() {
        let verify = await validateFields.fieldValidate.validateGroupName(editGroupName,settingsContext)
        if (!verify.status) {
            ModalInterface.createPopup(pageContext, "שגיאה", "נא לוודא שהשם בפורמט נכון!", "wrong-answer")
        }
        return verify.status
    }

    async function handleNameChange(event) {
        event.preventDefault();

        if (!(await verifyGroupName())) {
            return
        }
        try {
            const response = await api.post('/game/change-group-name', {
                user_id: userContext.userID,
                group_id: userContext.groupID,
                group_name: editGroupName
            })
            if (response.data.status == "success") {
                toggleForceUpdate()
                toggleNameChange()
                setEditGroupName(null)
            } else {
                ModalInterface.createPopup(pageContext, "שגיאה", languages.HE.errorMessages[response.data.message], 'wrong-answer')
            }
        } catch (err) {
            console.log(err);
        }
    }
    // Add message for multiple players added without paying.
    async function AddPlayerHandler() {
        console.log("remainingUnregistered ", remainingUnregistered)
        let body
        if(memberList.length == 1 && soloPlayer == 1){
            body = `האם אתה בטוח שאתה רוצה להוסיף חבר קבוצה נוסף? \nשים לב שתוכל להוסיף עוד חבר קבוצה אחד ללא תשלום!`
        } else if (remainingUnregistered > 0){
            body = `שים לב נותרו לך עוד  ${remainingUnregistered} חברי קבוצה להוסיף ללא תשלום נוסף \nהאם אתה בטוח שאתה רוצה להוסיף חבר קבוצה נוסף? ` 
        } else {
            body = `האם אתה בטוח שאתה רוצה להוסיף חבר קבוצה נוסף? \nשים לב הוספת חבר קבוצה נוסף תחויב בתשלום`
        }
        ModalInterface.createPopup(pageContext, "התראה",
            body, null, [{ title: "כן", onClick: () => ModalInterface.createPopup(pageContext, null, null, null, null, <AddUser toggleForceUpdate={toggleForceUpdate} />, null) },
            { "title": "לא", onClick: () => ModalInterface.closePopup(pageContext) }], null, false)
    }

    function handleSpinner(key) {
        for (let i = 0; i < memberList.length; ++i) {
            if (key == i) {
                setSpinnerArray(prev => ({
                    ...prev,
                    [i]: !spinnerArray[key]
                }))
            } else {
                setSpinnerArray(prev => ({
                    ...prev,
                    [i]: false
                }))
            }
        }

    }


    useEffect(() => {
            getGroup()
            getGroupLeader()
    }, [forceUpdate])

    useEffect(() => {
        window.location.hash = 'userinfo'
    }, [])

    return (
        <div className='user-info-container'>
            <div className='user-info-top'>
                {!isEdit ?
                    <h1>{groupName ? groupName : "אין שם לקבוצה!"}</h1> :
                    <form onSubmit={handleNameChange} className='user-info-top-form'>
                        <input className='user-info-top-input' onChange={handleText} placeholder={groupName} />
                    </form>
                }
                <FontAwesomeIcon onClick={toggleNameChange} icon={faEdit} />
                {displaySave ? <FontAwesomeIcon onClick={handleNameChange} icon={faFloppyDisk}/> : null}
            </div>
            <div className='user-info-mid '>
                <div className='user-info-group-info'>
                    <h4>משתתפים</h4>
                    {memberList ? Object.keys(memberList).map((user, index) => {
                        return (
                            <UserBox key={index} index={index} showSpinner={spinnerArray[index]} handleSpinner={handleSpinner} toggleForceUpdate={toggleForceUpdate} name={memberList[user].first_name + ' ' + memberList[user].last_name} forceUpdate={forceUpdate} setForceUpdate={setForceUpdate} color={getColor()} user_id={memberList[user].id} isLeader={isLeader} />
                        )
                    }): null}
                    {/* {isLeader ?
                        <CustomButton onClick={AddPlayerHandler} content={"הוסף חבר קבוצה"} backgroundColor={"rgba(55, 47, 43, 0.6)"}></CustomButton> :
                        null} */}
                </div>
                <div className='user-info-ship-container'>
                    <div className='user-info-ship-center'>
                        <div className='user-info-ship-display'>
                            <Svg fileName='logo_main' />
                        </div>
                    </div>
                </div>
            </div>
            <div className='user-info-bottom'>

            </div>
        </div>
    )
}

export default PageUserInfo


