
let settings = {
    version: "1.0.1",
    answersRegex_MAX_ANSWER_LENGTH: 20,
    answersRegex_MIN_ANSWER_LENGTH: 2,
    answersRegex_allowHebrewLetters: true,
    answersRegex_allowEnglishLetters: true,
    answersRegex_allowSpacesLetters: true,
    answersRegex_allowNumbersLetters: true,
    answersRegex_allowSpeicalLetters: false,
    answersRegex_allowApostropheSign: false,
    answersRegex_allowQuoteSign: false,
    answersRegex_allowLatinSpecialLetters: false,
    groupLimits_groupNameLimit_MAX_NAME_LENGTH: 20,
    groupLimits_groupNameLimit_MIN_NAME_LENGTH: 2,
    groupLimits_groupNameLimit_allowHebrewLetters: true,
    groupLimits_groupNameLimit_allowEnglishLetters: true,
    groupLimits_groupNameLimit_allowSpacesLetters: true,
    groupLimits_groupNameLimit_allowNumbersLetters: true,
    groupLimits_groupNameLimit_allowSpeicalLetters: false,
    groupLimits_groupNameLimit_allowApostropheSign: false,
    groupLimits_groupNameLimit_allowQuoteSign: false,
    groupLimits_groupNameLimit_allowLatinSpecialLetters: false,
    groupLimits_groupNameLimit_allowPeriod: false,
    registerLimits_NAME_MAX_LEN: 32,
    registerLimits_NAME_MIN_LEN: 2,
}


export default settings;