import React, { useContext, useState, useEffect } from 'react'
import './Prizes.css'
import { PageContext, UserContext , SettingsContext} from '../../context/AppContext';
import ProgressInterface from '../../modules/progressInterface'
import UserBox from '../../components/user-box/UserBox'
import validateFields from '../../modules/validateFields'
import Svg from '../../modules/svg'
import api from '../../components/api/AxiosConfig'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit , faFloppyDisk } from '@fortawesome/free-solid-svg-icons'
import CustomButton from '../../components/custom-button/CustomButton';
import ModalInterface from '../../modules/modalInterface';
import AddUser from '../../components/modal/add-user/AddUser';
import languages from '../../language';
import img from '../../images/prizes.png'

function Prizes({show}) {
    useEffect(() => {
        window.location.hash = 'prizes'
    }, [])

    return (
        <div style={{textAlign:'center'}}>
            <img src={img}></img>
        </div>
    )
}

export default Prizes


