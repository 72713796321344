import React from 'react'
import './HintModal.css'

function HintModal({hint , show, handleShowHint}) {
  return (
    <div className={`hint-modal-container`} id='hint-modal' >
        <div className='hint-modal-exit' onClick={handleShowHint}>X</div>
        <div className='hint-modal-text-container'>
        <p dangerouslySetInnerHTML={{ __html: hint }}></p>
        </div>
    </div>
  )
}

export default HintModal