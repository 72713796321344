import React, { useEffect, useState, useContext } from 'react'
import './StartGameCard.css'
import CustomButton from '../../custom-button/CustomButton'
import Svg from '../../../modules/svg'
import ModalInterface from '../../../modules/modalInterface'
import { PageContext } from '../../../context/AppContext'
import languages from '../../../language'
import animateCSS from '../../../modules/animate'

export default function StartGameCard({ title, description, img_src, header_img_src, onClickEvent }) {
    const { pageContext } = useContext(PageContext)
    const [buttonWidth, setButtonWidth] = useState()
    const [buttonHeight, setButtonHeight] = useState()

    function handleInfo() {
        let body = ""
        if (title == "adult") {
            body = languages.HE.gameTexts['adult info']
        } else {
            body = languages.HE.gameTexts['junior info']
        }
        ModalInterface.createPopup(pageContext, 'הסבר', body, null, null, null, true,null)
    }

    function calculateSize() {
        if (window.innerWidth <= 768) {
            setButtonWidth("102px")
            setButtonHeight("59px")
        } else {
            setButtonWidth("263px")
            setButtonHeight("59px")
        }
    }

    useEffect(() => {
        calculateSize()

    }, [])
    return (
        <div className='start-game-card-content '>
            <div className='start-game-card-top'>
                <header className='start-game-card-header'>
                    <Svg fileName={header_img_src} />
                </header>
            </div>
            <div className='start-game-card-mid'>
                <div className='start-game-card-image'>
                    <Svg fileName={img_src} />
                </div>
            </div>
            <div className='start-game-card-sub-mid'>
                {/* <div className='start-game-card-description' onClick={() => handleInfo()}>
                    הסבר
                </div> */}
            </div>
            <div className='start-game-card-bot'>
                <div className='start-game-card-button'>
                    <CustomButton width={'buttonWidth'} height={buttonHeight} onClick={onClickEvent} backgroundGradiant={{ top: "rgba(203, 82, 66, 1)", bottom: "rgba(224, 52, 52, 1)" }} fontColor={"#fff"} content={"התחל משחק"} />
                </div>
            </div>

        </div>

    )
}
