import React, { useContext, useEffect, useState } from 'react'
import Card from '../../components/card/Card'
import api from '../../components/api/AxiosConfig'
import StartGameCard from '../../components/card/startgame/StartGameCard'
import './PageStartGame.css'
import { PageContext, UserContext } from '../../context/AppContext';
import ProgressInterface from '../../modules/progressInterface';
import ModalInterface from '../../modules/modalInterface'
import languages from '../../language'
import animateCSS from '../../modules/animate'
import Raincoins from '../../animations/raincoins'

export default function PageStartGame(props) {
  const { pageContext, setPageContext } = useContext(PageContext)
  const { userContext } = useContext(UserContext)
  const [cardWidth, setCardWidth] = useState()
  const [cardHeight, setCardHeight] = useState()
  const [headerJuniorLogo, setHeaderJuniorLogo] = useState()
  const [animatedClass, setAnimatedClass] = useState()
  const [currentMinigame, setCurrentMinigame] = useState()

  function calculateSize() {

    if (window.innerWidth <= 768) {
      setCardWidth("300px")
      setCardHeight("420px")
      setHeaderJuniorLogo("startgame_junior_logo_phone")
    } else {
      setCardWidth("434px")
      setCardHeight("554px")
      setHeaderJuniorLogo("startgame_junior_logo")
    }
  }

  async function getGroupMinigame() {
    try {
      const response = await api.post('/game/get-group-current-minigame', {
        group_id: userContext.groupID
      })
      if(response.data.status == "success"){
        setCurrentMinigame(response.data.currentMinigame)
      }
    } catch (err){
      console.log(err);
    }
  }

  async function handleStartGame(status) {
    let minigame = status == 1 ? currentMinigame : 4
    try {
      const response = await api.post('/game/request-access', {
        user_id: userContext.userID
      })
      if (response.data.status == 'success') {
        setPageContext(prev=> ({
          ...prev,
          showStartGame: false,
          showMinigame: true,
          minigameStatus: status,
          currentMinigame: minigame
        }))
      } else {
        ModalInterface.createPopup(pageContext, "התראה", languages.HE.errorMessages[response.data.message], 'wrong-answer')
      }
    } catch (err) {
      console.log(err);
    }
  }


  useEffect(() => {
    calculateSize()
    getGroupMinigame()
    window.location.hash = 'startgame'
  }, [])

  return (
    <div className='startgame-container'>

      <div className={`startgame-card-container`}>
        <Card backgroundColor={"rgba(255, 255, 255, 1)"} position={'static'} width={cardWidth} height={cardHeight} animation={'animate__bounceInRight'}>
          <StartGameCard
            title={"junior"}
            onClickEvent={() => handleStartGame(0)}
            header_img_src={window.innerWidth <= 768 ? "logo_junior_big" : "logo_junior_big"}
            img_src="big_map" />
        </Card>
        {/* <Card backgroundColor={"rgba(46,61,70,1)"} position={'static'} width={cardWidth} height={cardHeight} animation={'animate__bounceInLeft'}>
          <StartGameCard
            title={"adult"}
            onClickEvent={() => handleStartGame(1)}
            header_img_src="logo_main"
            img_src="adult_map" />
        </Card> */}


      </div>

    </div>
  )
}
