import './App.css';
import "../src/modules/sockets"
import React, { useState, useEffect, useRef } from 'react'
import PageLogin from './pages/login/PageLogin';
import Modal from './components/modal/Modal';
import api from './components/api/AxiosConfig'
import PageMinigame from './pages/minigame/PageMinigame';
import PageStartGame from './pages/startgame/PageStartGame';
import PageWelcome from './pages/welcome/PageWelcome';
import PageQuestions from './pages/questions/PageQuestions';
import { UserContext, PageContext, AnimContext, SettingsContext } from './context/AppContext';
import Header from './components/header/Header';
import Cookies from 'js-cookie';
import BackButton from './components/back-button/BackButton';
import ProgressCircle from './components/progress-circle/ProgressCircle';
import PageUserInfo from './pages/userinfo/PageUserInfo';
import ModalInterface from './modules/modalInterface';
import PageCoinBank from './pages/coinbank/PageCoinBank';
import PageScanner from './pages/scanner/PageScanner';
import languages from './language';
import 'font-awesome/css/font-awesome.min.css';
import OrientationModal from './components/modal/orientation/OrientationModal';
import utils from './modules/utils';
import axiosInstance from './components/api/AxiosConfig';
import 'animate.css';
import Raincoins from './animations/raincoins';
import Confetti from 'react-confetti'
import SoundPlayer from './audio/SoundPlayer';
import ScreenModal from './components/modal/screen-modal/ScreenModal';
import settings from './settings';
import SoundMenu from './components/sound-menu/SoundMenu';
import soundHelper from './audio/SoundHelper';
import { SocketComponent } from '../src/modules/sockets';
import ProgressInterface from './modules/progressInterface';
import WidthModal from './components/modal/width/WidthModal';
import Prizes from './pages/Prizes/Prizes';

function App() {
  const [orientaionModal, setOrientationModal] = useState(false)
  const [widthModal, setwidthModal] = useState(false)

  const isMobile = useRef(false)
  const bodyRef = useRef()
  const volumeRef = useRef(null)


  const [modalState, setModalState] = useState({
    title: '',
    body: '',
    img: '',
    visible: false,
    buttons: [],
    children: null,
    isCloseButton: true,
    style: {}
  })

  const [settingsContext, setSettingsContext] = useState(settings)

  const [recaptchaState, setRecaptchaState] = useState({
    recaptchaLoginVisibility: false,
    recaptchaLoginStatus: false,
    recaptchaAnswerVisibility: false,
    recaptchaAnswerStatus: false
  })


  const [userContext, setUserContext] = useState({
    firstName: '',
    lastName: '',
    userID: '',
    groupID: '',
    avatarPath: '',
    firstLogin: 0,
    coinBalance: 0,
    phoneNumber: null,
    newUser: false,
  })

  const [animContext, setAnimContext] = useState({
    showConfetti: false,
    showCoinRain: false,
  })

  const [soundContext, setSoundContext] = useState({
    allowSound: true,
    volume: 0.5,
    showMenu: false,
    allowBackground: false,
    allowUISound: true,
    playBackground: false,
    playClick: false,
    playConfetti: false,
    playError: false,
    playLaugh: false,
    playAhoy: false,
    playCoins: false
  })

  const [showProgress, setShowProgress] = useState(false)

  const [pageContext, setPageContext] = useState({
    showLogin: false,
    showWelcome: true,
    showStartGame: false,
    showMinigame: false,
    showQuestions: false,
    showUserInfo: false,
    showCoinBank: false,
    showScanner: false,
    showSpread: false,
    showPrizes: false,
    showProgress: showProgress,
    setShowProgress: setShowProgress,
    showCamera: false,
    currentMinigame: 0,
    selectedDeck: 0,
    minigameStatus: -1, //0 == kids game 1 == adults
    selectedQuestion: null,
    modalState: modalState,
    loginCaptchaVisibility: false,
    loginCaptchaStatus: false,
    setModalState: setModalState,
    recaptchaState: recaptchaState,
    setRecaptchaState: setRecaptchaState,
    soundContext: soundContext,
    setSoundContext: setSoundContext,
    questionList: {},
    allowSounds: false,
    screenModal: false,
    screenUrl: null,
    missingToken: true,
    region: null,
    regionName: null,
    allowedDeckId: null,
  })

  const usercontext = { userContext, setUserContext }
  const pagecontext = { pageContext, setPageContext }
  const animcontext = { animContext, setAnimContext }
  const settingscontext = { settingsContext, setSettingsContext }

  async function checkForQRCode(userID, groupID) {
    const queryParams = new URLSearchParams(window.location.search)
    const qrCodeValue = String(queryParams.get('qr_code')).replace(/"/g, '')
    if (qrCodeValue == "null") {
      return
    }
    try {
      const response = await api.post('/bank/qr_scan/use', {
        user_id: userID,
        group_id: groupID,
        qr_code: qrCodeValue
      })
      if (response.data.status == "success") {
        ModalInterface.createPopup(pageContext, "התראה", ` יש! מצאתם ${response.data.amount} מטבעות!`, 'right-answer')
        setUserContext(prevUserContext => ({
          ...prevUserContext,
          coinBalance: response.data.userBalance
        }));
      } else {
        ModalInterface.createPopup(pageContext, "שגיאה", languages.HE.errorMessages[response.data.message], 'wrong-answer')
      }

    } catch (err) {
      console.log(err);
    }

  }


  async function isUserLoggedIn() {
    const cookieValue = Cookies.get('access-token');
    const queryParams = new URLSearchParams(window.location.search)
    if (!cookieValue) {
      const qrCodeValue = String(queryParams.get('qr_code')).replace(/"/g, '')
      if (qrCodeValue == "null") {
        return
      } else {
        ModalInterface.createPopup(pageContext, "התראה", "עלייך להתחבר ולסרוק את הברקוד שוב", 'wrong-answer')
      }
      return
    }
    try {
      ProgressInterface.showProgressBar(setPageContext, pageContext)
      const response = await api.post('/auth/is-user-logged-in', {
        token: cookieValue,
        version: settingsContext.version
      })
      if (response.data['status'] === "success") {
        let currentMinigame = response.data['currentMinigame']
        handleContext(response.data['userInfo'], response.data['coin_balance'])
        if (response.data['userInfo'][0].first_login === 0) {
          setPageContext(prev => ({
            ...prev,
            showLogin: false,
            showWelcome: false,
            showStartGame: true,
            currentMinigame: 1
          }))
        } else if (queryParams.get('coins')) {
          setPageContext(prev => ({
            ...prev,
            showLogin: false,
            showCoinBank: true,
            currentMinigame: currentMinigame
          }))
        } else if (queryParams.get('users')) {
          setPageContext(prev => ({
            ...prev,
            showLogin: false,
            showUserInfo: true,
            currentMinigame: currentMinigame
          }))
        } else {
          setPageContext(prev => ({
            ...prev,
            showLogin: false,
            showStartGame: true,
            currentMinigame: currentMinigame
          }))
        }
        checkForQRCode(response.data.userInfo[0].id, response.data.userInfo[0].group_id)
      }
    } catch (err) {
      console.log(err);
    } finally {
      ProgressInterface.hideProgressBar(setPageContext, pageContext)
    }
  }

  function handleContext(userData, balance) {
    setUserContext({
      ...userContext,
      firstName: userData[0].first_name,
      lastName: userData[0].last_name,
      userID: userData[0].id,
      groupID: userData[0].group_id,
      avatarPath: userData[0].avatarPath,
      firstLogin: userData[0].first_login,
      coinBalance: balance
    })
  }

  function toggleSound() {
    if (soundContext.showMenu) {
      setSoundContext(prev => ({
        ...prev,
        showMenu: false
      }))
    } else {
      setSoundContext(prev => ({
        ...prev,
        showMenu: true
      }))
    }

  }

  async function validateUserExists() {
    try {
      const response = await api.post('/auth/validate-user-exist', {
        user_id: userContext.userID
      })
      if (response.data.status != "success") {
        utils.handleLogout(setUserContext, setPageContext, setModalState, recaptchaState, setRecaptchaState, modalState, pageContext, showProgress, setShowProgress, soundContext, setSoundContext)
        ModalInterface.createPopup(pageContext, "התראה", languages.HE.errorMessages[response.data.message], 'wrong-answer')
      }
    } catch (err) {
      console.log(err);
    }
  }

  async function appInit() {
    try {
      const response = await api.get('/load-app')
      if (response.data.settings) {
        const version = settings.version;
        if (version != response.data.settings['version']) {
          ModalInterface.createPopup(pageContext, "התראה", languages.HE.errorMessages["update required"], "wrong-answer")
          return
        }
        for (const key in response.data.settings) {
          setSettingsContext(prev => ({
            ...prev,
            key: response.data.settings[key]
          }))
        }
      }
    } catch (err) {
      console.log(err);
    }
  }

  useEffect(() => {
    appInit()
    isUserLoggedIn()
  }, []);

  useEffect(() => {
  }, [orientaionModal])

  useEffect(() => {
    if (pageContext.showLogin || pageContext.showWelcome) {
      return
    }
    validateUserExists()
    if (pageContext.screenModal) {
      setPageContext(prev => ({
        ...prev,
        screenModal: false,
        screenUrl: null
      }))
    }
  }, [pageContext.showCamera, pageContext.showCoinBank, pageContext.showLogin,
  pageContext.showMinigame, pageContext.showQuestions, pageContext.showScanner,
  pageContext.showStartGame, pageContext.showStartGame, pageContext.showUserInfo,
  pageContext.showWelcome])

  useEffect(() => {
    function detectDeviceType() {
      const userAgent = navigator.userAgent.toLowerCase();
      if (/mobile/i.test(userAgent)) {
        isMobile.current = true
      } else {
        isMobile.current = false
      }
    }

    detectDeviceType();

    function handleOrientationChange() {
      // Perform actions when orientation changes
      if (window.orientation === 0 || window.orientation === 180) {
        setOrientationModal(false)
      } else if (window.orientation === 90 || window.orientation === -90) {
        if (isMobile.current) {
          setOrientationModal(true)
        }
      }
    }

    function detectInitialOrientation() {
      if (window.matchMedia('(orientation: portrait)').matches) {
        setOrientationModal(false)
      } else if (window.matchMedia('(orientation: landscape)').matches) {
        if (isMobile.current) {
          setOrientationModal(true)
        }

      }
    }

    function detectInitialWidth() {
      if (window.matchMedia('(orientation: portrait)').matches && window.innerWidth >= 481) {
        console.log("ETAMAR!!!")
        setwidthModal(true)
      }
    }

    detectInitialWidth()
    if (!isMobile.current) {
      setwidthModal(true)
    }
    // Add event listener for orientation change
    window.addEventListener('orientationchange', handleOrientationChange);

    detectInitialOrientation();

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('orientationchange', handleOrientationChange);
    };
  }, []);

  useEffect(() => {
    if (window.location.protocol !== 'https:') {
      if (window.location.hostname != 'localhost') {
        window.location.href = `https://${window.location.hostname}${window.location.pathname}`
      }

    }
  }, [])

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (bodyRef.current.contains(event.target)) {
        soundHelper.playSound(setSoundContext, true)
      }
    };

    document.addEventListener('mousedown', handleOutsideClick);

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);

  useEffect(() => {

  }, [settings])

  const tokenMissingHandler = () => {
    setPageContext(prev => ({
      ...prev,
      missingToken: true,
    }))
    Cookies.remove('access-token')
    ModalInterface.createPopup(pageContext, "התראה", "עלייך לסרוק את הברקוד במתחם הקניות בכדי להתחבר למשחק", 'wrong-answer')
  }
  // checking for valid token per BIG center.
  const validateToken = async () => {
    const queryParams = new URLSearchParams(window.location.search)
    const token = queryParams.get('token')
    console.log("token ", token)
    try {
      if (!token) {
        tokenMissingHandler()
      } else {
        const response = await api.post('/auth/get_big_location', { token: decodeURIComponent(token) })
        console.log(response.data)
        if (response.data.status == 'failure') {
          tokenMissingHandler()
          return
        }
        const region_data = response.data.data
        console.log("region_data", region_data)
        setPageContext(prev => ({
          ...prev,
          missingToken: false,
          region: region_data.region_name,
          regionName: region_data.formal_name,
          allowedDeckId: region_data.card_deckid
        }))
      }
    } catch (e) {
      tokenMissingHandler();
      console.log(e)
    }
  }

  useEffect(() => {
    validateToken()
  }, [])
  useEffect(() => {
    console.log(pageContext)
  }, [pageContext])


  axiosInstance.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response && error.response.status === 401) {
        utils.handleLogout(setUserContext, setPageContext, pageContext.setModalState, pageContext.recaptchaState, pageContext.setRecaptchaState, pageContext.modalState, pageContext, showProgress, setShowProgress, soundContext, setSoundContext)
        ModalInterface.createPopup(pageContext, "שגיאה", languages.HE.errorMessages[error.response.data.error], 'wrong-answer')
      }
      else if (error.response && error.response.status === 429) {
        ModalInterface.createPopup(pageContext, "שגיאה", "שלחת יותר מידי בקשות לשרת! אנא המתן ונסה שנית", 'wrong-answer')
      }
      else if (error.response && error.response.status === 405) {
        utils.handleLogout(setUserContext, setPageContext, setModalState, recaptchaState, setRecaptchaState, modalState, pageContext, showProgress, setShowProgress, soundContext, setSoundContext)
        ModalInterface.createPopup(pageContext, "התראה", languages.HE.errorMessages["User already online!"], 'wrong-answer')
      }
      return Promise.reject(error);
    }
  );

  window.addEventListener('beforeunload', (event) => {
    event.preventDefault();
    event.returnValue = ''
  })

  window.addEventListener('popstate', (event) => {
    event.preventDefault();
    if (pageContext.screenModal) {
      setPageContext(prev => ({
        ...prev,
        screenModal: false,
        screenUrl: null
      }))
    }
  });


  useEffect(() => {
    async function getGroupBalance() {
      try {
        const response = await api.post('bank/get-coin-balance', {
          user_id: userContext.userID,
          group_id: userContext.groupID
        })

        if (response.data.status == 'success') {
          setUserContext(prev => ({
            ...prev,
            coinBalance: response.data.balance
          }))
        }
      } catch (err) {
        console.log(err);
      }
    }
    if (userContext.userID && userContext.groupID)
      getGroupBalance(userContext.userID, userContext.groupID)
  }, [userContext.userID, userContext.groupID])
  return (
    <>
      <main className='main' ref={bodyRef}>
        <UserContext.Provider value={usercontext}>
          <PageContext.Provider value={pagecontext}>
            <AnimContext.Provider value={animcontext}>
              <SettingsContext.Provider value={settingscontext}>
                <SocketComponent />
                {soundContext.allowSound ? <SoundPlayer soundContext={soundContext} setSoundContext={setSoundContext} /> : null}
                {animContext.showCoinRain ? <Raincoins display={animContext.showCoinRain} /> : null}
                {animContext.showConfetti ? <Confetti width={window.innerWidth} height={window.innerHeight} /> : null}
                <Modal title={modalState.title} body={modalState.body} visible={modalState.visible} buttons={modalState.buttons} img={modalState.img} isCloseButton={modalState.isCloseButton} style={modalState.style} >{modalState.children}</Modal>
                {pageContext.screenModal ? <ScreenModal setPC={setPageContext} url={pageContext.screenUrl} /> : null}
                {modalState.visible && pageContext.allowSounds ? <SoundPlayer sound={'modal'} /> : null}
                {modalState.visible || showProgress ? <div className='overlay'></div> : null}
                {showProgress ? <ProgressCircle /> : null}
                {orientaionModal ? <OrientationModal /> : null}
                {widthModal ? <WidthModal /> : null}
                <Header />
                {!modalState.visible && (pageContext.showMinigame || (pageContext.showQuestions && !pageContext.showCamera) || pageContext.showUserInfo || pageContext.showCoinBank || pageContext.showScanner) && window.innerWidth >= 480 ? <BackButton isMobile={isMobile} /> : null}
                {pageContext.showLogin ? <PageLogin /> : null}
                {pageContext.showWelcome ? <PageWelcome /> : null}
                {pageContext.showStartGame ? <PageStartGame /> : null}
                {pageContext.showMinigame ? <PageMinigame /> : null}
                {pageContext.showQuestions ? <PageQuestions /> : null}
                {pageContext.showUserInfo ? <PageUserInfo /> : null}
                {pageContext.showCoinBank ? <PageCoinBank /> : null}
                {pageContext.showScanner ? <PageScanner /> : null}
                {pageContext.showPrizes ? <Prizes /> : null}
                {soundContext.showMenu ? <SoundMenu volumeRef={volumeRef} soundContext={soundContext} setSoundContext={setSoundContext} /> : null}
                <div ref={volumeRef} className='volume-controller' onClick={() => toggleSound()}>
                  {soundContext.allowSound && soundContext.volume > 0 ? <i className="fa fa-volume-up"></i> : <i className="fa fa-volume-off"></i>}
                </div>
              </SettingsContext.Provider>
            </AnimContext.Provider>
          </PageContext.Provider>
        </UserContext.Provider>
      </main>
    </>


  );
}

export default App;