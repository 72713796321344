import React , {useState , useRef , useEffect , useContext} from 'react'
import Svg from '../../../modules/svg'
import CustomButton from '../../custom-button/CustomButton'
import ModalInterface from '../../../modules/modalInterface'
import { PageContext, UserContext } from '../../../context/AppContext'

function ModalInterval({title , body , time , img}) {
    const {userContext } = useContext(UserContext)
    const {pageContext , setPageContext } = useContext(PageContext)
    const intervalRef = useRef()
    const [timer , setTimer] = useState(time)
    const [formattedTime , setFormattedTime] = useState()
    const [displayTimer , setDisplayTimer] = useState(true)

    function handleClose(){
        ModalInterface.closePopup(pageContext)
    }

    useEffect(() => {
        intervalRef.current = setInterval(() => {
          if(timer < 300){
            setDisplayTimer(true)
            setTimer(timer - 1);
          } else {
            setDisplayTimer(false)
            setTimer(timer - 1);
            var hours = Math.floor(timer / 3600);
            var minutes = Math.floor((timer % 3600) / 60);
            var seconds = (timer % 60).toString().padStart(2, '0');
            let formattedTime = `${hours}:${minutes}:${seconds}`
            setFormattedTime(formattedTime)
          }
          
        }, 1000);
        return () => clearInterval(intervalRef.current);
      }, [timer]);
    
      useEffect(() => {
        if (timer === 0) {
            ModalInterface.closePopup(pageContext)
            clearInterval(intervalRef.current);
        }
      }, [timer]);
  return (
    <div>
        <div className='modal-top'>
              {img ? 
              <div className='modal-top-img'>
                <Svg fileName={img} />
              </div>
              : null}
          </div>
          <div className='modal-middle'>
            <div className='modal-title-container'>
                <h2>{title}</h2>
            </div>
            <div className='modal-body-container'>
                <h4>{body[0]} {displayTimer ? timer : formattedTime} {body[1]}</h4>
            </div>
          </div>
          <div className='modal-bottom'>
             <CustomButton content={"סגור"} zIndex={100} onClick={handleClose}  backgroundGradiant={{ top: "rgba(203, 82, 66, 1)", bottom: "rgba(224, 52, 52, 1)" }} ></CustomButton>
          </div>
    </div>
  )
}

export default ModalInterval