import React, { useEffect, useState, useContext, useRef } from 'react'
import './QuestionCard.css'
import api from '../../api/AxiosConfig'
import { UserContext, PageContext, AnimContext ,SettingsContext} from '../../../context/AppContext'
import CustomButton from '../../custom-button/CustomButton'
import ModalInterface from '../../../modules/modalInterface'
import fieldValidate from '../../../modules/validateFields'
import languages from '../../../language'
import utils from '../../../modules/utils'
import HintModal from '../../modal/hintmodal/HintModal'
import ProgressInterface from '../../../modules/progressInterface'
import soundHelper from '../../../audio/SoundHelper'

export default function QuestionCard(props) {
  const { userContext, setUserContext } = useContext(UserContext)
  const { pageContext, setPageContext } = useContext(PageContext)
  const { animContext, setAnimContext } = useContext(AnimContext)
  const { settingsContext, setSettingsContext } = useContext(SettingsContext)

  const [answer, setAnswer] = useState()
  const [hint, setHint] = useState()
  const [showHint, setShowHint] = useState(false)
  const [buttonAdjustments, setButtonAdjustments] = useState({ short: {}, long: {} })
  const [question, setQuestion] = useState()
  const [isLeader, setIsLeader] = useState(false)
  const [disableButton, setDisableButton] = useState(false)

  const [allowHint, setAllowHint] = useState(false)
  const [allowSkip, setAllowSkip] = useState(false)

  const [leader, setLeader] = useState()
  const leaderString = utils.generateRandomString(4)

  const intervalRef = useRef()
  const [isCooldown, setIsCooldown] = useState(false)
  const [timer, setTimer] = useState(0)
  const [startAnim, setStartAnim] = useState(false)

  const [coinPrices, setCoinPrices] = useState({ hint: pageContext.currentMinigame == 1 ? 5 : 10, skip: 10 })



  async function verifyAnswer() {
    let verify = await fieldValidate.fieldValidate.validateAnswer(answer, settingsContext)

    if (!verify.status) {
      ModalInterface.createPopup(pageContext, "שגיאה", "נא לבדוק את פורמט התשובה!", "wrong-answer")
    }
    return verify.status
  }

  async function sendAnswer(event) {
    if (!isLeader) {
      ModalInterface.createPopup(pageContext, 'שגיאה', 'רק קפטן של קבוצה יכול לענות על חידות!', 'wrong-answer')
      return
    }
    if (!(await verifyAnswer())) {
      return
    }
    setDisableButton(true)
    try {
      // ProgressInterface.showProgressBar(setPageContext, pageContext)
      const response = await api.post('/game/verify-answer', {
        "answer": answer,
        "question_id": props.question_id,
        "minigame_id": pageContext.currentMinigame,
        "user_id": userContext.userID,
        "group_id": userContext.groupID,
        "group_leader": leader,
      })
      if (response.data.status === "success") {
        soundHelper.playSound(pageContext.setSoundContext, false , true , false , false , false ,false)
      
        setAnimContext(prev => ({
          ...prev,
          showConfetti: true
        }))
        setTimeout(() => {
          setAnimContext(prev => ({
            ...prev,
            showConfetti: false
          }))
        }, 5000)
        setPageContext(prev => ({
          ...prev,
          showCamera: true
        }))
        ModalInterface.createPopup(pageContext, "ענית נכון", "תשובה נכונה, ממשיכים לצילום התמונה!", "right-answer", null , null , true , null , false)
        props.handleCards(answer)
      } else if (response.data.status == "failure") {
        props.wrongAnswerAnim()
        getGroupAnswerAttempts()
        ModalInterface.createPopup(pageContext, "תשובה שגויה", "התשובה שכתבת אינה נכונה נסה שוב או המשך", "wrong-answer")
      } else if (response.data.status == 'required') {
        ModalInterface.createPopup(pageContext, 'התראה', `עלייך להמתין ${timer} שניות לפני שליחה חוזרת!`, 'wrong-answer')
      }
      // ProgressInterface.hideProgressBar(setPageContext, pageContext)
      setDisableButton(false)
    } catch (error) {
      console.log(error);
    }
  }

  function handleSize() {
    if (window.innerWidth <= 768) {
      setButtonAdjustments({
        ...buttonAdjustments,
        short: {
          width: pageContext.currentMinigame == 2 ? "219px" : "104px",
          height: "43px"
        },
        long: {
          width: "219px",
          height: "43px"
        }
      })
    } else {
      setButtonAdjustments({
        ...buttonAdjustments,
        short: {
          width: pageContext.currentMinigame == 2 ? "300px" : "143px",
          height: "59px"
        },
        long: {
          width: "300px",
          height: "59px"
        }
      })
    }
  }

  async function handleHintClick() {
    if (!isLeader) {
      ModalInterface.createPopup(pageContext, "שגיאה", languages.HE.errorMessages['not a team leader'], "wrong-answer")
      return
    }
    if (!hint) {
      if (!await getHintStatus()) {
        let body
        if (pageContext.currentMinigame == 1) {
          body = 'האם אתה בטוח שאתה רוצה לקנות רמז עבור 5 מטבעות?'
        }
        else if (pageContext.currentMinigame == 2) {
          body = 'האם אתה בטוח שאתה רוצה לקנות רמז עבור 10 מטבעות?'
        }
        ModalInterface.createPopup(pageContext,
          'התראה',
          body,
          null,
          [{ title: 'כן', "disabled": true, onClick: () => getHint() },
          { title: "לא", onClick: () => ModalInterface.closePopup(pageContext) }],
          null,
          false)
      } else {
        getHint()
      }

    } else {
      handleShowHint()
    }
  }

  async function getHintStatus() {
    try {
      const response = await api.post('/bank/get-hint-status', {
        question_id: props.question_id,
        group_id: userContext.groupID,
        user_id: userContext.userID,
        minigame_id: pageContext.currentMinigame
      })
      if (response.data.status == "success") {
        setCoinPrices(prev => ({
          ...prev,
          hint: 0
        }))
        return true
      } else {
        setCoinPrices(prev => ({
          ...prev,
          hint: pageContext.currentMinigame == 1 ? 5 : 10
        }))
        return false
      }
    } catch (err) {

    }
  }

  async function getHint() {
    try {
      if (!hint) {
        const response = await api.post('/bank/get-hint', {
          question_id: props.question_id,
          group_id: userContext.groupID,
          user_id: userContext.userID,
          minigame_id: pageContext.currentMinigame
        })
        if (response.data.status === "success") {
          handleContext(response.data.balance)
          setHint(response.data.hint[0].hint)
          handleShowHint()
          ModalInterface.closePopup(pageContext)
          setCoinPrices(prev => ({
            ...prev,
            hint: 0
          }))
        } else {
          let body = languages.HE.errorMessages[response.data.message]
          ModalInterface.createPopup(pageContext, "שגיאה", body , "wrong-answer" ,
           null,
            null , false)
          ModalInterface.addButtons(pageContext, [{"title": "קנה מטבעות!", "disabled": true , onClick: () => moveToCoinBank()}, {"title": "סגור", onClick: () => ModalInterface.closePopup(pageContext)}])
        }
      } else {
        handleShowHint()
      }
    } catch (error) {
      console.log(error);
    }
  }

  async function moveToCoinBank() {
    console.log("moving");
    if (pageContext.showCamera || pageContext.showWelcome || pageContext.showLogin) {
      return
    }
    try {
      const response = await api.post('/game/request-access', {
        user_id: userContext.userID
      })
      if (response.data.status == 'success') {
        if (userContext.groupID) {
          setPageContext(prev => ({
            ...prev,
            showLogin: false,
            showWelcome: false,
            showStartGame: false,
            showMinigame: false,
            showQuestions: false,
            showUserInfo: false,
            showCoinBank: true,
            showSpread: false,
            showProgress: false,
            selectedDeck: 0,
          }))
        }
        ModalInterface.closePopup(pageContext)
      } else {
        ModalInterface.createPopup(pageContext, "התראה", languages.HE.errorMessages[response.data.message], 'wrong-answer')
      }
    } catch (err) {
      console.log(err);
    }


  }

  async function getGroupAnswerAttempts() {
    try {
      const response = await api.post('/game/get-group-answer-attempts', {
        user_id: userContext.userID,
        group_id: userContext.groupID,
        question_id: props.question_id,
        minigame_id: pageContext.currentMinigame
      })
      if (response.data.status == 'required') {
        setIsCooldown(true)
        setTimer(Math.ceil(response.data.time))
      } else {
        setIsCooldown(false)
        setTimer(0)
      }
      ProgressInterface.hideProgressBar(setPageContext, pageContext)
    } catch (err) {
      console.log(err)
    }
    ProgressInterface.hideProgressBar(setPageContext, pageContext)
  }

  function handleContext(balance) {
    setUserContext({
      ...userContext,
      coinBalance: balance
    })
  }

  function handleShowHint() {
    if (showHint) {
      setStartAnim(true)
      setTimeout(() => setShowHint(false), 500)

    } else {
      setStartAnim(false)
      setShowHint(true)
    }
  }

  function skipQuestionHandler() {
    if (!isLeader) {
      ModalInterface.createPopup(pageContext, "שגיאה", languages.HE.errorMessages['not a team leader'], "wrong-answer")
      return
    }
    ModalInterface.createPopup(pageContext,
      'התראה',
      'האם אתה בטוח שאתה רוצה לדלג על השאלה עבור 10 מטבעות?',
      null,
      [{ title: 'כן',"disabled": true ,onClick: () => skipQuestion() },
      { title: "לא", onClick: () => ModalInterface.closePopup(pageContext) }],
      null,
      false)
  }

  function test(){
    console.log("test");
  }

  async function skipQuestion() {
    try {
      const response = await api.post('bank/skip-question', {
        question_id: props.question_id,
        group_id: userContext.groupID,
        user_id: userContext.userID,
        minigame_id: pageContext.currentMinigame
      })
      if (response.data.status == "success") {
        handleContext(response.data.balance)
        setPageContext(prev=> ({
          ...prev,
          showQuestions: false,
          showMinigame: true,
          showSpread: true,
          selectedQuestion: 0
        }))
        ModalInterface.closePopup(pageContext)
        if (response.data.minigame == "done") {
          let body
          if (response.data.next_minigame) {
            body = `כל הכבוד! סיימתם את השלב ה${utils.getHebrewNumber(pageContext.currentMinigame)}. בהצלחה בשלב ה${utils.getHebrewNumber(pageContext.currentMinigame + 1)}!`
          } else {
            body = `כל הכבוד סיימתם את השלב ה${utils.getHebrewNumber(pageContext.currentMinigame)} , השלב ה${utils.getHebrewNumber(pageContext.currentMinigame + 1)} יפתח ב - ${utils.convertDate(response.data.next_minigame_time)}`
          }
          ModalInterface.createPopup(pageContext, "עברת את השלב", body, "right-answer")
        } else {
            ModalInterface.createPopup(pageContext, "דילגת על החידה", `ממשיכים לחידה הבאה!\nשים לב שנשארו לך ${response.data.skip_remaining} דילוגים`, "right-answer")
        }
      } else {
        let body = languages.HE.errorMessages[response.data.message]
        ModalInterface.createPopup(pageContext, "שגיאה", body, "wrong-answer" ,
        [{title: "קנה מטבעות!" ,"disabled": true ,onClick: () => moveToCoinBank()}, {title: "סגור", onClick: () => ModalInterface.closePopup(pageContext)}],
         null , false)
      }
    } catch (err) {
      console.log(err);
    }
  }

  function handleChange(event) {
    if (event.target.name === "answer") {
      setAnswer(event.target.value)
    } else {
      setLeader(event.targer.value)
    }

  }

  async function getGroupLeader() {
    try {
      const response = await api.post('/game/get-team-leader', {
        user_id: userContext.userID,
        group_id: userContext.groupID
      })
      if (response.data.status == "success") {
        if (userContext.userID == response.data.team_leader.team_leader) {
          setIsLeader(true)
        }
      }

    } catch (err) {
      console.log(err);
    }
  }


  function extractQuestion() {
    ProgressInterface.showProgressBar(setPageContext, pageContext)
    for (let index in pageContext.questionList) {
      if (pageContext.questionList[index].id == props.question_id) {
        setQuestion(pageContext.questionList[index].question)
      }
    }
  }

  function allowAssist() {
    if (pageContext.currentMinigame === 1) {
      setAllowHint(true)
      setAllowSkip(true)
    }
    if (pageContext.currentMinigame === 2) {
      setAllowHint(true)
      setAllowSkip(false)
    }
    if (pageContext.currentMinigame === 3) {
      setAllowHint(false)
      setAllowSkip(false)
    }
  }

  function calculateTime() {
    let minutes = Math.round(timer / 60)
    let seconds = Math.round(timer % 60)
    return `${minutes}:${seconds}`
  }

  useEffect(() => {
    intervalRef.current = setInterval(() => {
      setTimer(timer - 1);
    }, 1000);
    return () => clearInterval(intervalRef.current);
  }, [timer]);

  useEffect(() => {
    if (timer === 0) {
      setIsCooldown(false)
      clearInterval(intervalRef.current);
    }
  }, [timer]);

  useEffect(() => {
    if (pageContext.showQuestions) {
      handleSize()
      extractQuestion()
      getGroupLeader()
      allowAssist()
      getHintStatus()
      getGroupAnswerAttempts()
    }
  }, [])


  return (

    <div className='question-card-content '>
      {isCooldown ? <div className='question-overlay'>
        <p>ניסית לענות על השאלה יותר מידי פעמיים</p>
        <p>השאלה חסומה ל-{timer} שניות</p>
      </div> : null}
      <div className='question-card-question' dangerouslySetInnerHTML={{ __html: question }}>

      </div>
      <form className='question-card-form'>
        {isLeader ? <input name='answer' type={"text"} onChange={handleChange} placeholder={"התשובה שלך"} required></input> : null}
        <input autoComplete='off' name={leaderString} type={"text"} onChange={handleChange} style={{ display: 'none' }}></input>
      </form>
      {showHint ?
        <div className={`question-card-hint-placeholder animate__animated ${showHint ? 'animate__fadeIn' : ''} ${startAnim ? 'animate__fadeOut' : ''}`}>
          <HintModal handleShowHint={handleShowHint} show={showHint} hint={hint} />
        </div>
        : null}
      <div className='question-card-button-container'>
        <div className='question-card-button-sub-container-split-buttons'>
          {allowHint ?
            <div>
              <CustomButton onClick={handleHintClick} widthFold="100%" width={buttonAdjustments.short.width} height={buttonAdjustments.short.height} content={"רמז"} backgroundGradiant={{ top: "rgba(193, 187, 31, 1)", bottom: "rgba(91, 113, 4, 1)" }} img='small-coin' imgContent={coinPrices.hint}></CustomButton>
            </div>
            : null}
          {allowSkip ?
            <div>
              <CustomButton onClick={skipQuestionHandler} width={buttonAdjustments.short.width} height={buttonAdjustments.short.height} content={"דלג"} img='small-coin' imgContent={coinPrices.skip} backgroundGradiant={{ top: "rgba(156, 135, 122, 1)", bottom: "rgba(74, 52, 32, 1)" }}></CustomButton>
            </div>
            : null}
        </div>
        <div>
          <CustomButton width={buttonAdjustments.long.width} height={buttonAdjustments.long.height} onClick={sendAnswer} content={"המשך"} backgroundGradiant={{ top: "rgba(21, 138, 110, 1)", bottom: "rgba(3, 62, 81, 1)" }} oneTime={false} block={disableButton}></CustomButton>
        </div>

      </div>

    </div>
  )
}
